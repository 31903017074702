import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
<>
<h2>Privacy Page</h2>
</>
  );
};

export default Privacy;
