import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import {  
  BrowserRouter as Router,  
  Routes,  
  Route,  
  Link  
}   
from 'react-router-dom';  
import Thankyou from "./pages/thankyou/Thankyou";
import Privacy from "./pages/privacy/Privacy";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4E0q-7NLTfd3p9boFNDobDG3YN9iChMQ",
  authDomain: "matrix-labs-main.firebaseapp.com",
  projectId: "matrix-labs-main",
  storageBucket: "matrix-labs-main.appspot.com",
  messagingSenderId: "714045750698",
  appId: "1:714045750698:web:6031cab5f8878a4bad7fd3",
  measurementId: "G-3Q9V688SSV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />}/>
        <Route exact path="/thankyou" element={<Thankyou/>}/>
        <Route exact path="/privacy-policy" element={<Privacy/>}/>
      </Routes> 
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
