import React from "react";
import "./thank.css";

const Thankyou = () => {
  return (
<>
<h2>Thank You Page</h2>
</>
  );
};

export default Thankyou;
